import { useEffect, useRef, useState } from 'react';

import { listOfCardsServerTransformer } from '@/lib/graphql/transformers';
import { debug, isOperator, isVariable, toCurrency } from '@/lib/utils';
import ProductosFlexible from '../Productos/Productos';

import type { TypeHeading } from '@/components/types';
import type { TypeCartProductGraphQL } from '@/lib/graphql/types';
import type { TypeProduct } from '@/types';
import type { FunctionComponent } from 'react';

type TypeProductConnectif = {
  id: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  name: string;
  uri: string;
  regularPrice: string;
  salePrice: string;
  price: string;
  databaseId: string;
  sellerInfo: {
    name: string;
  };
  type: TypeProduct;
  primaryCategory: {
    path: string;
    name: string;
  };
  stockStatus: 'instock' | 'outofstock';
};

const transformConnectifProduct = (
  product: TypeProductConnectif,
): TypeCartProductGraphQL & { uri: string; isConnectif: true } => ({
  ...product,
  image: {
    ...product.image,
    mediaDetails: {
      height: 0,
      width: 0,
    },
  },
  isConnectif: true,
  price: toCurrency(Number(product.price)),
  regularPrice: toCurrency(Number(product.regularPrice)),
  salePrice: toCurrency(Number(product.salePrice)),
  databaseId: parseInt(product.databaseId),
  stockStatus:
    product.stockStatus.toLocaleLowerCase() === 'instock'
      ? 'IN_STOCK'
      : 'OUT_OF_STOCK',
  onSale: product.regularPrice !== product.salePrice,
  sellerInfo: {
    ...product.sellerInfo,
    slug: '',
  },
  link: '',
  customTemplate: '',
  shippingClasses: {
    edges: [],
  },
  productCategories: {
    edges: [],
  },
  metaData: [],
  caracteriSticasDelProducto: {
    opciones: {
      customDateDelivery: undefined,
    },
    datosDeProducto: {
      marca: [],
    },
  },
  status: 'publish',
  ...(!isOperator(product.sellerInfo?.name) &&
    isVariable(product.type) && {
      variations: {
        edges: [
          {
            node: {
              sellerInfo: {
                ...product.sellerInfo,
                slug: '',
              },
              databaseId: parseInt(product.databaseId),
            },
          },
        ],
      },
    }),
});

type TypeRecommendsConnectif = {
  bloque: {
    connectifId: string;
    headingType?: TypeHeading;
    title?: string;
  };
  itemIndex: number;
  id: string;
  className?: string;
};

const RecommendsConnectif: FunctionComponent<TypeRecommendsConnectif> = ({
  bloque,
  itemIndex,
  id,
  className,
}: TypeRecommendsConnectif) => {
  const [products, setProducts] = useState<Array<TypeCartProductGraphQL>>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new MutationObserver((records, observer) => {
      // We only want to observe the first mutation
      if (records.length > 0 && records[0] !== undefined) {
        const record = records[0];
        const products: NodeListOf<HTMLAnchorElement> =
          (record.target as Element)?.querySelectorAll(
            '.cn_json_container .cn_json',
          ) ?? [];

        const formattedProducts = [...products]
          .map((product) => {
            try {
              return transformConnectifProduct(
                JSON.parse(product.innerHTML) as TypeProductConnectif,
              );
            } catch (error) {
              debug(error);
              return null;
            }
          }) // TS doesn't understand that the filter removes nulls
          .filter(Boolean) as TypeCartProductGraphQL[];

        setProducts(listOfCardsServerTransformer(formattedProducts as any[]));

        observer.disconnect();
      }
    });

    if (ref.current)
      observer.observe(ref.current, {
        childList: true,
      });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div id={bloque.connectifId} ref={ref} style={{ display: 'none' }} />

      {products.length > 0 && (
        <ProductosFlexible
          id={id}
          itemIndex={itemIndex}
          bloque={{
            productos: products,
            itemListId: `connectif-${bloque.connectifId}-${itemIndex}`,
            itemListName: `Connectif: ${bloque.title ?? bloque.connectifId}`,
            ...(bloque.title && { titulo: bloque.title }),
            ...(bloque.headingType && { headingType: bloque.headingType }),
          }}
          {...(className && { className })}
        />
      )}
    </>
  );
};

export default RecommendsConnectif;

RecommendsConnectif.displayName = 'RecommendsConnectif';
